<template>
  <div class="article-list">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-d-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('brandNews')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="list-box">
      <div class="title">
        <c-title :width="16" :type="2">
          <div class="text">{{$t('brandNews')}}</div>
        </c-title>
      </div>
      <div class="list">
        <div class="item" v-for="(item,index) in list" :key="index" @click="goDetails(item.id)">
          <div class="item-title">{{item.articleTitle}}</div>
          <div>{{formatTime(item.releaseTime)}}</div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import cTitle from '@/components/cTitle/cTitle.vue'

import { articleList } from '@/api/academic'

export default {
  name: 'articleList',
  components: {
    cTitle
  },
  data() {
    return {
      pageNo: 1,
      pageSize: 10,
      total: 0,
      list: []
    }
  },
  created() {
    if (this.$route.query.columnCode) {
      this.columnCode = this.$route.query.columnCode
      this.getList()
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNo = val
      this.getList()
    },
    getList() {
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        columnCode: this.columnCode
      }
      articleList(params).then((res) => {
        if (res.code === 10000) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    formatTime(time) {
      time = time.slice(0, 10).replace(/\-/g, ' / ')
      return time
    },
    goDetails(id) {
      this.$router.push({
        path: `/brandDynamic/dynamicItem?id=${id}`
        // name: 'articleDetails',
        // params: {
        //   id,
        //   columnCode: this.columnCode
        // }
      })
    }
  }
}
</script>
<style lang="scss">
.article-list {
  flex: 1;
  width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .breadcrumb {
    width: 1140px;
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 0 0 45px;
  }
  .list-box {
    flex: 1;
    // width: 1140px;
    // margin: 0 auto;
    background: url('../../static/img/academic/content_bg.jpg') no-repeat center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    min-height: 950px;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 28px 0 40px 0;
      .text {
        color: #595757;
        font-size: 30px;
        line-height: 30px;
      }
    }
    .list {
      padding: 0 100px;
      .item {
        font-size: 16px;
        line-height: 22px;
        color: #3e3a39;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        position: relative;
        padding: 23px 22px 23px 0;
        display: flex;
        align-items: center;

        &:first-of-type {
          border-top: 1px solid #ccc;
        }
        .item-title {
          padding: 0 0 0 22px;
          background: url('../../static/img/academic/dian1.png') no-repeat left
            5px center;
          background-size: 12px 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 800px;
          overflow: hidden;
          cursor: pointer;
        }
        // &::before {
        //   content: "";
        //   position: absolute;
        //   top: 50%;
        //   left: 0;
        //   margin: -6px 0 0 0;
        //   width: 12px;
        //   height: 12px;
        //   border-radius: 50%;
        //   border: 1px solid #d7c8b9;
        // }
      }
    }
    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 0 58px 0;
      .el-pager {
        font-weight: initial;
        li {
          background: initial;
        }
        .number {
          font-size: 15px;
          color: #595757;
          padding: initial;
          min-width: 30px;
          height: 30px;
          margin: 0 5px;
        }
        .active {
          color: #ffffff;
          background: #843322;
          border-radius: 50%;
        }
      }
      .btn-prev {
        transform: rotateY(180deg);
      }
      .btn-prev,
      .btn-next {
        height: 30px;
        background: url('../../static/img/brand/page_icon.png') no-repeat center;
        background-size: 18px 30px;
        .el-icon {
          display: none;
        }
      }
    }
  }
}
</style>